<script setup lang="ts">
import { useContentValidation } from '~/stores/contentValidation'
import { useEditor } from '~/stores/editor'

const contentValidation = useContentValidation()
const editor = useEditor()
const route = useRoute()
const policyId = computed(() => route.params.id as string)

const quickDrawerOpen = ref(false)

const errorsByPolicy = computed(() => {
  if (!policyId.value) {
    return []
  }

  return contentValidation.errorsByPolicy(policyId.value)
})

const warningsByPolicy = computed(() => {
  if (!policyId.value) {
    return []
  }

  return contentValidation.warningsByPolicy(policyId.value)
})

onMounted(() => {
  editor.getPolicies()
  document.body.style.overscrollBehavior = 'none'
})

onBeforeUnmount(() => {
  document.body.style.overscrollBehavior = ''
})
</script>

<template>
  <AuthPermissionsGuard
    :permissions="[PERMISSIONS.writeTeamContent, PERMISSIONS.readTeamContent]"
  >
    <div class="flex w-full">
      <EditorQuickDrawer
        v-show="quickDrawerOpen"
        @close="quickDrawerOpen = false"
      />
      <div class="bg-surface-bg min-h-screen w-full">
        <header
          class="flex h-10 w-full items-center justify-between border-b px-4"
        >
          <div class="flex items-center space-x-2">
            <UiButton
              class="h-6"
              :variant="quickDrawerOpen ? undefined : 'outlined'"
              size="xs"
              @click="quickDrawerOpen = !quickDrawerOpen"
            >
              Policies
            </UiButton>

            <div
              v-if="errorsByPolicy.length"
              class="bg-error-300 border-error text-error-700 flex items-center space-x-2 rounded-full border-2 px-2 py-1"
            >
              <div class="i-ph-warning block h-5 w-5" />

              <p class="body-2">{{ errorsByPolicy[0].message }}</p>

              <p v-if="errorsByPolicy.length > 1">
                Total errors: {{ errorsByPolicy.length }}
              </p>
            </div>
            <UiMenu v-else-if="warningsByPolicy.length">
              <template #activator="{ toggle }">
                <button
                  class="bg-warning-300 border-warning text-warning-700 flex items-center space-x-2 rounded-full border-2 border-2 px-2 py-1"
                  type="button"
                  @click="toggle(true)"
                >
                  <div class="i-ph-warning block h-5 w-5" />

                  <p class="body-2">{{ warningsByPolicy[0].message }}</p>

                  <p v-if="warningsByPolicy.length > 1">
                    Total errors: {{ warningsByPolicy.length }}
                  </p>
                </button>
              </template>
              <ul>
                <li
                  v-for="warning in warningsByPolicy"
                  :key="warning.policy_id"
                >
                  {{ warning.message }}
                </li>
              </ul>
            </UiMenu>
          </div>

          <NuxtLink
            to="/"
            class="btn h-6 border border-neutral-300 text-neutral-800 hover:border-neutral-600 hover:bg-neutral-100"
          >
            Exit
            <span class="i-ph-sign-out ml-2" />
          </NuxtLink>
        </header>

        <slot />
      </div>
    </div>
    <template #fallback>
      <Teleport to="body">
        <div class="z-1000 fixed inset-0 flex items-center justify-center">
          <div>
            <h2 class="subtitle mb-4">
              You don't have permission to view this page
            </h2>
            <NuxtLink to="/" class="btn btn-outlined h-10">
              Back to dashboard
            </NuxtLink>
          </div>
        </div>
      </Teleport>
    </template>
  </AuthPermissionsGuard>
</template>
