<script setup lang="ts">
import type { Api } from '@rialtic/api'
import { useEditor } from '~/stores/editor'

const emit = defineEmits(['close'])

const editor = useEditor()

const activeFilters = reactive({
  editType: [] as string[],
  engine: [] as string[],
  status: [] as string[],
  source: [] as string[],
  topic: [] as string[],
})

const search = ref('')

const filterPolicies =
  (filters: typeof activeFilters) =>
  (policies: Pick<Api.Policy, 'id' | 'latest_review_status' | 'name'>[] = []) =>
    policies.filter((policy) => {
      if (policy.id === 'dev_policy_1' && !process.dev) return false
      if (!policy.name) return false
      if (
        filters.status.length &&
        !filters.status.includes(policy.latest_review_status || '')
      )
        return false

      return true
    })
const pinnedPolicies = useSimpleSearch(
  computed(() => filterPolicies(activeFilters)(editor.pinnedPolicies)),
  search,
  'name',
)
const recentPolicies = useSimpleSearch(
  computed(() => filterPolicies(activeFilters)(editor.recentPolicies)),
  search,
  'name',
)
const allPolicies = useSimpleSearch(
  computed(() => filterPolicies(activeFilters)(editor.allPolicies)),
  search,
  'name',
)

const policiesById = computed(() => {
  if (!search.value) {
    return []
  }

  const containsId = /^policy_[0-9]+$/g.test(search.value)

  if (!containsId) {
    return []
  }
  return editor.allPolicies.filter(({ id }) => id?.startsWith(search.value))
})

const updateFilters = (updates: Record<string, string>, filterName: string) => {
  switch (filterName) {
    case 'status':
      activeFilters[filterName] = Object.values(updates)
      break

    default:
      activeFilters[filterName] = Object.keys(updates)
      break
  }
}

useRouter().afterEach(() => {
  emit('close')
})

const { list, containerProps, wrapperProps } = useVirtualList(allPolicies, {
  // Keep `itemHeight` in sync with the item's row.
  itemHeight: 64,
})
</script>

<template>
  <aside
    v-bind="containerProps"
    class="bg-surface w-140 z-100 fixed left-0 h-screen overflow-y-auto border-r lg:relative"
  >
    <header class="z-110 sticky top-0">
      <div
        class="bg-surface-bg flex h-10 w-full items-center justify-between border-b px-4"
      >
        <FilterPolicyReviewStatus
          class="h-8"
          @change="(val) => updateFilters(val, 'status')"
        />
        <button class="btn-icon" @click="$emit('close')">
          <div class="i-ph-x-bold" />
        </button>
      </div>
      <div class="px-4 py-2">
        <UiSearch v-model="search" placeholder="Search policies" />
      </div>
    </header>

    <ul v-if="pinnedPolicies.length" class="mb-4 border-b">
      <li class="subtitle-2 px-4 text-neutral-800">Pinned</li>
      <li v-for="policy in pinnedPolicies" :key="`pinned_${policy.id}`">
        <EditorQuickDrawerPolicy v-bind="{ policy }" />
      </li>
    </ul>

    <ul v-if="recentPolicies.length" class="mb-4 border-b">
      <li class="subtitle-2 px-4 text-neutral-800">Recent</li>
      <li v-for="policy in recentPolicies" :key="`recent_${policy.id}`">
        <EditorQuickDrawerPolicy v-bind="{ policy }" />
      </li>
    </ul>

    <ul>
      <li v-if="search" class="subtitle-2 px-4 text-neutral-800">Results</li>
      <li v-else class="subtitle-2 px-4 text-neutral-800">All</li>
      <li v-for="policy in policiesById">
        <EditorQuickDrawerPolicy
          :key="`by_id_${policy.id}`"
          v-bind="{ policy }"
        />
      </li>
    </ul>
    <ul v-bind="wrapperProps">
      <li v-for="{ data: policy } in list">
        <EditorQuickDrawerPolicy
          :key="`all_${policy.id}`"
          v-bind="{ policy }"
        />
      </li>
    </ul>
  </aside>
</template>
